import React from "react";
import theme from "../../theme.scss";

export const BookIcon = ({ width = 30, colour = `${theme.white}` }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="book-icon"
    >
      <path
        d="M18.5004 1.80291C17.5519 1.00994 16.3 0.679878 15.0836 0.902144L11.8984 1.48064C11.1633 1.6158 10.4945 1.99392 10 2.55486C9.50429 1.99314 8.83398 1.61463 8.09648 1.48064L4.91641 0.902144C2.65274 0.488082 0.482031 1.98729 0.0679683 4.25057C0.0226559 4.49783 0 4.74902 0 5.0002V13.9943C0 16.0076 1.43985 17.733 3.4207 18.0935L8.65899 19.0459C9.5457 19.2068 10.4539 19.2068 11.3406 19.0459L16.5832 18.0935C18.5625 17.7314 20 16.0064 20 13.9943V5.0002C20.0008 3.76426 19.4508 2.59198 18.5 1.80253L18.5004 1.80291ZM9.16719 17.4404C9.09727 17.4303 9.02734 17.4189 8.95703 17.4064L3.71953 16.4549C2.53086 16.2385 1.6668 15.2033 1.66719 13.9947V5.00058C1.66719 3.61972 2.78633 2.50058 4.16719 2.50058C4.31797 2.50058 4.46875 2.51463 4.61718 2.54159L7.80039 3.12481C8.59063 3.26935 9.16524 3.95723 9.16719 4.76075V17.4408V17.4404ZM18.334 13.9947C18.334 15.2029 17.4703 16.2385 16.2816 16.4549L11.0441 17.4064C10.9742 17.4189 10.9043 17.4306 10.834 17.4404V4.76037C10.834 3.9549 11.4098 3.26464 12.2023 3.1205L15.3863 2.53731C16.7449 2.29005 18.0465 3.19082 18.2934 4.54941C18.3203 4.69824 18.334 4.8494 18.3336 5.00058V13.9947H18.334Z"
        fill={colour}
      />
    </svg>
  );
};
