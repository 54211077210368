import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  loadingBaseData: 0,
  server: null,
  clientVersion: "",
  qrCode: null,
  initialDeviceId: null,
  fetchInitialState: true,
  refreshUser: false,
  refreshStaff: false,
  refreshOrganisationHubs: false,
  layoutPreference: "grid",
  showSideDrawer: false,
  ziplineAuth: "",
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setLoadingBaseData(state, action) {
      state.loadingBaseData = action.payload;
    },
    setServer(state, action) {
      state.server = action.payload;
    },
    setClientVersion(state, action) {
      state.clientVersion = action.payload;
    },
    setQrCode(state, action) {
      state.qrCode = action.payload;
    },
    setInitialDeviceId(state, action) {
      state.initialDeviceId = action.payload;
    },
    setFetchInitialState(state, action) {
      state.fetchInitialState = action.payload;
    },
    setRefreshUser(state, action) {
      state.refreshUser = action.payload;
    },
    setRefreshStaff(state, action) {
      state.refreshStaff = action.payload;
    },
    setRefreshOrganisationHubs(state, action) {
      state.refreshOrganisationHubs = action.payload;
    },
    setLayoutPreference(state, action) {
      state.layoutPreference = action.payload;
    },
    setShowSideDrawer(state, action) {
      state.showSideDrawer = action.payload;
    },
    setCommunityAuth(state, action) {
      state.ziplineAuth = action.payload;
    },
  },
});

export const {
  setLoading,
  setLoadingBaseData,
  setServer,
  setClientVersion,
  setQrCode,
  setInitialDeviceId,
  setFetchInitialState,
  setRefreshUser,
  setRefreshStaff,
  setRefreshOrganisationHubs,
  setLayoutPreference,
  setShowSideDrawer,
  setCommunityAuth,
} = sessionSlice.actions;

export default sessionSlice.reducer;
